import React from "react";
import Layout from "../components/layout/Layout";
import CarouselLastProjects from "../components/carousel/carousellastprojects/CarouselLastProjects";
import Contact from "../components/contact/Contact";
import styles from "./LastProjects.module.css";

const Kitchens = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <h1>
          &copy;PRO
          <span className="red">MOB</span>{" "}
        </h1>
      </div>
      <CarouselLastProjects />
      <Contact />
    </Layout>
  );
};

export default Kitchens;
