import React from "react";
import Layout from "../components/layout/Layout";
import styles from "./Contact.module.css";
import ContactComponent from "../components/contact/Contact";
import { FiClock } from "react-icons/fi";

const Contact = () => {
  return (
    <Layout>
      <section className={styles.contact}>
        <h1>
          &copy;PRO
          <span className="red">MOB</span>{" "}
        </h1>
        <h3>Contacto</h3>
        <div className={styles.container}>
          <article>
            <div className={styles.contact__content}>
              <h3>Estamos aquí para ayudarte</h3>
              <p>
                Si necesita más información, no dude en ponerse en contacto con
                nosotros. Le atenderemos encantados. Estamos a tu disposición
                para dar forma a tus ideas.
              </p>
            </div>
            <div className={styles.contact__content_second}>
              <h3>Horario</h3>
              <h5>De Lunes a Viernes</h5>
              <span>
                <FiClock className={styles.contact__style_icon} />
                Mañana 08:00 h. a 13:30 h.
              </span>
              <br />
              <span>
                <FiClock className={styles.contact__style_icon} />
                Tarde 15:00 h. a 18:00 h.
              </span>
            </div>
          </article>
          <article>
            <iframe
              title="map"
              className={styles.map}
              width="650"
              height="450"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Calle%20Sant%20Antoni%20numero%2015,%20Sant%20Joan%20Despi.%20Barcelona&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            />
          </article>
        </div>
        <ContactComponent />
      </section>
    </Layout>
  );
};

export default Contact;
